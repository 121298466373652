$(document).ready(function () {
  let mainNav = document.getElementById("js-menu");
  let navBarToggle = document.getElementById("js-navbar-toggle");
  let navContainer = document.getElementById("landing-navbar");

  navBarToggle.addEventListener("click", function() {

    if (mainNav.classList.contains("shown")) {
      navBarToggle.innerHTML = "<i class='fas fa-bars'></i>";
    } else {
      navBarToggle.innerHTML = "<i class='fas fa-times'></i>";
    }
    mainNav.classList.toggle("shown");
    navContainer.classList.toggle('mobile-nav-scroll');
  });
})

// let prevScrollpos = window.pageYOffset;
// const landingNav = document.getElementById('landing-navbar');
// window.addEventListener('scroll', function () {
//   let currentScrollPos = window.pageYOffset;
//   let navHeight = $('#landing-navbar').outerHeight();
//   if (prevScrollpos > currentScrollPos) {
//     landingNav.style.top = "0";
//   } else {
//     landingNav.style.top = `-${navHeight}px`;
//   }
//   prevScrollpos = currentScrollPos;
// });
